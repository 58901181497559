import React from "react";
import { useTranslation } from "react-i18next";

import { Props } from "src/App/@types/Props";

const Main: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <main>
      <div className="wrapper">
        <div className="container-fluid">{props.children}</div>
      </div>
    </main>
  );
};

export default Main;
