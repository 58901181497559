import React, { useState, createContext, useContext } from "react";
import { Props } from "src/App/@types/Props";
import { TranslationContext } from "src/App/Context/TranslationContext";
import { useTranslation } from "react-i18next";

const ApiContext = createContext(null);

const ApiBoundry: React.FC<Props> = (props: Props) => {
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const { texts } = useContext(TranslationContext);
  const { i18n } = useTranslation();

  return (
    <ApiContext.Provider value={{ isApiError, setIsApiError }}>
      {props.children}
      {isApiError ? (
        <p className="error">{texts[i18n.language]?.app?.api_error}</p>
      ) : (
        <></>
      )}
    </ApiContext.Provider>
  );
};

export { ApiContext, ApiBoundry };
