import React, { useState, useEffect, useContext } from "react";

import Header from "src/App/Components/UserZone/Layout/Header/Header";
import Main from "src/App/Components/UserZone/Layout/Main/Main";
import Footer from "src/App/Components/UserZone/Layout/Footer/Footer";

import { TranslationContext } from "src/App/Context/TranslationContext";
import { useTranslation } from "react-i18next";

import InvoiceForm from "./Forms/InvoiceForm/InvoiceForm";
import { getUserDetails } from "src/App/Lib/Helper/Api";

const UserZone = () => {
  const { i18n } = useTranslation();
  const { texts } = useContext(TranslationContext);
  const [userData, setUserData] = useState<object | false>(false);

  useEffect(() => {
    try {
      const userData = getUserDetails();
      if (userData.expired) {
        alert(texts[i18n.language].app.sessionExpired);
        window.location.href =
          texts?.default?.logoutUrl || "https://inside.benefit-plus.eu";
      }
      setUserData(userData);
    } catch (e) {
      alert(texts[i18n.language].app.sessionExpired);
      window.location.href =
        texts?.default?.logoutUrl || "https://inside.benefit-plus.eu";
    }
  }, []);

  return (
    <>
      <Header userData={userData} />
      <Main>
        <InvoiceForm userData={userData} />
      </Main>
      <Footer />
    </>
  );
};

export default UserZone;
