import React, { useContext } from "react";
import * as packageJson from "../../../../../../package.json";
import { TranslationContext } from "src/App/Context/TranslationContext";
import { Props } from "src/App/@types/Props";
import { useTranslation } from "react-i18next";

const Footer: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const { texts } = useContext(TranslationContext);

  return (
    <footer className="mt-3 p-4">
      <div className="row">
        <div className="links col-4">
          <div className="wrapper">
            <h2>{texts[i18n.language]?.app.rest}</h2>
            <ol>
              <li>
                <a target="_blank" href="https://profirmy.benefit-plus.cz/zasady-ochrany-osobnich-udaju/">
                  {texts[i18n.language]?.app.gdpr}
                </a>
              </li>
              <li>
                <a target="_blank" href="https://inside.benefit-plus.eu/Pages/Logout.aspx">
                  {texts[i18n.language]?.app.logout}
                </a>
              </li>
            </ol>
          </div>
        </div>
        <div className="links col-4"></div>
        <div className="contact col-4">
          <div className="wrapper float-end">
            <h2>{texts[i18n.language]?.app.contact}</h2>
            <div className="phone p-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 57.957 57.978"
                fill="#ffffff"
              >
                <g id="telephone-2" transform="translate(0.247 0.25)">
                  <path
                    id="Path_1428"
                    data-name="Path 1428"
                    d="M12.515,38.207A60.978,60.978,0,0,0,32.8,54.1a31.568,31.568,0,0,0,11.35,3.359c.274.012.536.024.81.024a9.4,9.4,0,0,0,7.289-3.132.26.26,0,0,0,.048-.06,30.684,30.684,0,0,1,2.3-2.382c.56-.536,1.131-1.1,1.679-1.667a5.751,5.751,0,0,0-.024-8.563l-7.158-7.158A5.794,5.794,0,0,0,44.9,32.586a5.989,5.989,0,0,0-4.24,1.918L36.4,38.767c-.393-.226-.8-.429-1.179-.619a14.728,14.728,0,0,1-1.31-.715,45.125,45.125,0,0,1-10.779-9.814,25.986,25.986,0,0,1-3.644-5.812c1.12-1.012,2.168-2.072,3.18-3.109.357-.369.727-.738,1.1-1.108A6.106,6.106,0,0,0,25.736,13.3a6.058,6.058,0,0,0-1.977-4.288L20.209,5.467c-.417-.417-.81-.822-1.215-1.239-.786-.81-1.608-1.644-2.418-2.394A5.938,5.938,0,0,0,12.384,0a6.113,6.113,0,0,0-4.24,1.846L3.69,6.3A9.152,9.152,0,0,0,.962,12.16a22.042,22.042,0,0,0,1.656,9.528A55.431,55.431,0,0,0,12.515,38.207ZM3.869,12.41A6.3,6.3,0,0,1,5.762,8.361L10.193,3.93a3.207,3.207,0,0,1,2.191-1.012,3.043,3.043,0,0,1,2.144,1.036c.8.738,1.548,1.513,2.358,2.334.4.417.822.834,1.239,1.262L21.674,11.1a3.234,3.234,0,0,1,1.12,2.227,3.234,3.234,0,0,1-1.12,2.227c-.369.369-.738.75-1.108,1.12-1.108,1.12-2.144,2.18-3.287,3.192l-.06.06a2.329,2.329,0,0,0-.6,2.644c.012.036.024.06.036.1a28.03,28.03,0,0,0,4.18,6.8A47.627,47.627,0,0,0,32.322,39.922a17.051,17.051,0,0,0,1.572.858,14.732,14.732,0,0,1,1.31.715c.048.024.083.048.131.071a2.49,2.49,0,0,0,1.155.3,2.526,2.526,0,0,0,1.775-.81L42.719,36.6a3.152,3.152,0,0,1,2.18-1.06,2.991,2.991,0,0,1,2.108,1.06l7.182,7.17c1.429,1.429,1.417,2.978-.036,4.49-.5.536-1.024,1.048-1.584,1.584a34.3,34.3,0,0,0-2.489,2.584A6.539,6.539,0,0,1,44.97,54.6c-.2,0-.417-.012-.619-.024A28.679,28.679,0,0,1,34.084,51.5,57.929,57.929,0,0,1,14.778,36.373,53.057,53.057,0,0,1,5.369,20.712,19.209,19.209,0,0,1,3.869,12.41Z"
                    transform="translate(-0.912 0)"
                    stroke="#000"
                    stroke-width="0.5"
                  />
                </g>
              </svg>

              <h3>+420 225 377 777</h3>
              <a target="_blank" href="mailto:info@benefit-plus.cz">info@benefit-plus.cz</a>
            </div>
          </div>
        </div>
      </div>
      <div className="row px-2">
        <div className="copyright mt-5 pt-4 col-12">
          <a href="https://www.benefit-plus.eu">
            Copyright 2022 Benefit Plus (v{packageJson.version})
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
