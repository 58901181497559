import React, { useState, useRef, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Markdown from "markdown-to-jsx";
import { TranslationContext } from "src/App/Context/TranslationContext";
import { useTranslation } from "react-i18next";
import { getUrl, uploadInvoice, parseInvoice } from "src/App/Lib/Helper/Api";
import { LoadingContext } from "src/App/Context/LoadingContext";
import { ApiContext } from "src/App/Context/ApiContext";

export const Upload = ({
  userData,
  setInvoiceData,
  setInvoiceFileID,
  setIsSent,
  isUploadEnabled,
  setIsParsing,
  setIsParsed,
  setIsFormWarning,
}) => {
  const { texts } = useContext(TranslationContext);
  const { t, i18n } = useTranslation();
  const invoiceInputRef = useRef<HTMLInputElement>(null);
  const { setIsApiError } = useContext(ApiContext);
  const { isLoading, setIsLoading } = useContext(LoadingContext);
  const [isFileAdded, setIsFileAdded] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>(null);
  const [pdfFileId, setPdfFileId] = useState<string>(null);

  const onClickFileSelected = (e: any) => {
    e.preventDefault();
    setIsParsed(false);
    setIsFormWarning(false);
    invoiceInputRef.current.click();
  };

  const onChangeFileSelected = (e: any) => {
    e.preventDefault();
    setIsSent(false);
    setIsParsed(false);
    setIsFileAdded(true);
    setIsLoading(true);
    setIsApiError(false)

    getUrl(userData.token, (res) => {
      setPdfUrl(res.data.upload);
      setPdfFileId(res.data.fileId);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      setIsParsing(false);
      setIsApiError(true)
      setIsFileAdded(false);
      invoiceInputRef.current.value = null;
    });
  };

  const onClickProcessInvoice = async (e: any) => {
    const file = invoiceInputRef.current.files[0];
    e.preventDefault();
    setIsLoading(true);
    setIsParsing(true);

    await uploadInvoice(pdfUrl, file, () => {
      parseInvoice(
        pdfFileId,
        userData.token,
        (res) => {
          setInvoiceData(res);
          setInvoiceFileID(pdfFileId);
          setIsLoading(false);
          setIsParsing(false);
          setIsParsed(true);
          setIsFileAdded(false);
          invoiceInputRef.current.value = null;
        },
        (err) => {
          setIsLoading(false);
          setIsParsing(false);
          invoiceInputRef.current.value = null;
        }
      );
    });
  };

  if (!isUploadEnabled) {
    return <></>;
  }

  return (
    <>
      <div className="mt-2 mb-3 section-upload">
        {texts[i18n.language]?.attention ? (
          <>
            <h4>{texts[i18n.language]?.app.attention}</h4>
            <Markdown>{texts[i18n.language]?.attention}</Markdown>
          </>
        ) : (
          <></>
        )}
        <Form.Group
          className={`mb-3 form-file ${isFileAdded ? "visible" : ""}`}
          controlId="formUploadInvoice"
        >
          <Form.Label>{texts[i18n.language]?.app.upload_invoice}</Form.Label>
          <Form.Control
            type="file"
            placeholder={texts[i18n.language]?.app.add_invoice}
            id="formUploadInvoiceId"
            ref={invoiceInputRef}
            onChange={onChangeFileSelected}
            required
            disabled={isLoading}
          />
          <div
            onClick={isLoading ? undefined : onClickFileSelected}
            className={`btn ${isFileAdded ? "green" : ""}`}
          >
            {!isFileAdded
              ? texts[i18n.language]?.app.select_file
              : texts[i18n.language]?.app.file_added}
          </div>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          onClick={onClickProcessInvoice}
          disabled={isLoading || !isFileAdded}
        >
          {texts[i18n.language]?.app.process_invoice}
        </Button>
      </div>
    </>
  );
};
