import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TranslationContext } from "src/App/Context/TranslationContext";

export const Breadcrumb = () => {
  const { i18n } = useTranslation();
  const { texts } = useContext(TranslationContext);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb mt-2 mb-5">
        <li className="breadcrumb-item">
          <a href="https://inside.benefit-plus.eu/pages/EmployeeVirtualAccount.aspx?SelectedTab=Benefits&BenefitsTabMode=BenefitList">
            {texts[i18n.language]?.app.benefits_eshop}
          </a>
        </li>
        <li className="breadcrumb-item">
          {texts[i18n.language]?.app.benefit_list}
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {texts[i18n.language]?.app.invoice_form}
        </li>
      </ol>
    </nav>
  );
};
