import React, { useContext, useState, useEffect } from "react";
import { TranslationContext } from "src/App/Context/TranslationContext";
import { useTranslation } from "react-i18next";
import { TIMERS_ENUM } from "src/App/Lib/Enum/Timers";

const getTimeLeft = (startTime) => {
  return Math.floor((Date.now() - startTime) / TIMERS_ENUM.toSeconds);
};

export const MsgFormSent = ({ isSent }) => {
  const { texts } = useContext(TranslationContext);
  const { i18n } = useTranslation();

  return isSent ? (
    <p className="ok">{texts[i18n.language]?.form?.formSent}</p>
  ) : (
    <></>
  );
};

export const MsgFormError = ({ isFormError }) => {
  const { texts } = useContext(TranslationContext);
  const { i18n } = useTranslation();

  return isFormError ? (
    <p className="error">{texts[i18n.language]?.form?.formError}</p>
  ) : (
    <></>
  );
};

export const MsgFormWarning = ({ isFormWarning }) => {
  const { texts } = useContext(TranslationContext);
  const { i18n } = useTranslation();

  return isFormWarning ? (
    <p className="warning">{texts[i18n.language]?.form?.invoiceError}</p>
  ) : (
    <></>
  );
};

export const MsgLoading = ({ isLoading }) => {
  return isLoading ? (
    <div className="loading">
      <img className="icon-loading" src="/assets/i/icons/loading.svg" />
    </div>
  ) : (
    <></>
  );
};

export const MsgParsingSteps = ({ isParsing, isParsed }) => {
  const { texts } = useContext(TranslationContext);
  const { i18n } = useTranslation();
  const [timer, setTimer] = useState<any>(false);
  const [show1, setShow1] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [show3, setShow3] = useState<boolean>(false);
  const [show4, setShow4] = useState<boolean>(false);

  const startTimer = (setTimer) => {
    const startTime = Date.now();
    const timer = setInterval(() => {
      setShow1(getTimeLeft(startTime) >= texts[i18n.language]?.form.show1Delay);
      setShow2(getTimeLeft(startTime) >= texts[i18n.language]?.form.show2Delay);
      setShow3(getTimeLeft(startTime) >= texts[i18n.language]?.form.show3Delay);
      setShow4(getTimeLeft(startTime) >= texts[i18n.language]?.form.show4Delay);

      if (getTimeLeft(startTime) >= TIMERS_ENUM.cancelTimeout)
        clearInterval(timer);

      setTimer(timer);
    }, TIMERS_ENUM.timeoutDelay);
  };

  useEffect(() => {
    if (isParsing) startTimer(setTimer);
    if (isParsed) {
      setShow1(false);
      setShow2(false);
      setShow3(false);
      setShow4(false);
    }
  }, [isParsing, isParsed]);

  if (isParsed) {
    clearInterval(timer);
  }

  return isParsing ? (
    <div className="extraMessages">
      {show1 && !show2 && !show3 && !show4 ? (
        <p className="info info1">{texts[i18n.language]?.form.show1}</p>
      ) : (
        <></>
      )}
      {show2 && !show3 && !show4 ? (
        <p className="info info2">{texts[i18n.language]?.form.show2}</p>
      ) : (
        <></>
      )}
      {show3 && !show4 ? (
        <p className="info info3">{texts[i18n.language]?.form.show3}</p>
      ) : (
        <></>
      )}
      {show4 ? (
        <p className="info info4">{texts[i18n.language]?.form.show4}</p>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};
