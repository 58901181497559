import React, { useContext } from "react";
import { TranslationContext } from "src/App/Context/TranslationContext";
import { useTranslation } from "react-i18next";

const Header = ({ userData }) => {
  const { t, i18n } = useTranslation();
  const { texts } = useContext(TranslationContext);
  const { onAppLangChange } = useContext(TranslationContext);

  return (
    <header>
      <div className="collapse" id="navbarHeader">
        <div className="container-fluid ml-5 mr-5">
          <div className="row">
            <div className="col-sm-12 py-12">
              <h4 className="text-white">{texts[i18n.language]?.app.menu}</h4>
              <ul className="list-unstyled">
                <li>
                  <a
                    href="https://inside.benefit-plus.eu/Pages/EmployeeVirtualAccount.aspx?SelectedTab=MyPage&MyPageTabMode=List&ID=0"
                    className="text-white"
                  >
                    {texts[i18n.language]?.app.go_back_to_account}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar navbar-dark shadow-sm">
        <div className="container-fluid ml-2 mr-2">
          <a href="#" className="navbar-brand d-flex align-items-center">
            {/*
            <img
              className="logo"
              src="/assets/i/logos/logo.png"
              alt="Benefit Plus"
            ></img>
            */}
            <img
              className="logo-white"
              src="/assets/i/logos/logo_white.svg"
            ></img>
          </a>
          <div className="wrapper">
            <div className="user">
              <div className="icon" />
              <div>
                <p>{userData?.user_name}</p>
                <p>{userData?.user_email}</p>
              </div>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              onClick={(e) => onAppLangChange(e, "cs-CZ")}
            >
              <i className="flag flag-cs-CZ"></i>
            </button>
            <button
              className="navbar-toggler"
              type="button"
              onClick={(e) => onAppLangChange(e, "en-US")}
            >
              <i className="flag flag-en-US"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
