import * as packageJson from "../../../../package.json";
import jwt from "jwt-decode";
import { JwtTokenResponse } from "src/App/@types/Api/JwtTokenResponse";
import { JwtTokenInterface } from "src/App/@types/Api/JwtTokenInterface";

const hostname = window.location.hostname;
const apiPath = packageJson.reactEnv.apiPath[hostname];


export const getUserDetails = () => {
  const params = new URLSearchParams(window.location.search);
  const jwtToken = params.get("jwt");
  const decodedjwtToken = jwt<JwtTokenInterface>(jwtToken);
  const now = Math.floor(Date.now() / 1000);

  return {
    user_id: decodedjwtToken?.employer || "",
    user_email: decodedjwtToken?.email || "",
    user_name: decodedjwtToken?.employer_name || "",
    user_phone: decodedjwtToken?.phone || "",
    token: jwtToken,
    expired: Boolean(decodedjwtToken?.exp < now),
  } as JwtTokenResponse;
};

export const getUrl = async (token: string, callback: CallableFunction) => {
  await fetch(`${apiPath}/url`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((res) => callback(res))
};

export const uploadInvoice = async (
  s3UploadUrl: string,
  file: File,
  callback: CallableFunction
) => {
  const formData = new FormData();
  const blob = new Blob([file], { type: file.type });
  formData.append("body", blob);

  await fetch(s3UploadUrl, {
    method: "PUT",
    body: file,
    // body: formData,
  })
    .then((res) => callback(res))
    .catch((err) => {
      console.error("Error:", err);
    });
};

export const parseInvoice = async (
  fileId: string,
  token: string,
  callback: CallableFunction,
  errorCallback: CallableFunction
) => {
  await fetch(`${apiPath}/invoices/${fileId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => callback(res))
    .catch((err) => {
      errorCallback(err);
      // console.error("Error:", err);
    });
};

export const sendInvoice = async (
  recipientCompany: string,
  recipientCompanyID: string,
  recipientAddress: string,
  invoiceCompany: string,
  invoiceCompanyID: string,
  invoiceId: string,
  amount: string,
  categoryId: string,
  employeeEmail: string,
  employeePhone: string,
  note: string,
  bankAcc: string = null,
  bankCode: string = null,
  invoiceVS: string = null,
  manualProccesing: boolean = false,
  invoiceFileID: string,
  token: string,
  callback: CallableFunction,
  errorCallback: CallableFunction
) => {
  await fetch(
    `${apiPath}/invoices/${invoiceFileID}/${
      manualProccesing ? "manual" : "submit"
    }`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipient: recipientCompany,
        recipient_ico: recipientCompanyID,
        recipient_addr: recipientAddress,
        bank_acc: bankAcc,
        bank_code: bankCode,
        supplier: invoiceCompany,
        ico: invoiceCompanyID,
        invoice_id: invoiceId,
        amount,
        var_number: invoiceVS,
        category_id: categoryId,
        employee_email: employeeEmail,
        employee_phone: employeePhone,
        note,
      }),
    }
  )
    .then((res) => res.json())
    .then((res) => callback(res))
    .catch((err) => {
      errorCallback(err);
      // console.error("Error:", err);
    });
};
