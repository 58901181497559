import React, { useState, useEffect, useContext } from "react";
import Markdown from "markdown-to-jsx";

import { LoadingContext } from "src/App/Context/LoadingContext";
import { TranslationContext } from "src/App/Context/TranslationContext";
import { InvoiceProps } from "src/App/@types/InvoiceProps";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "./components/Breadcrumb";
import { Upload } from "./components/Upload";
import { Invoice } from "./components/Invoice";
import {
  MsgFormSent,
  MsgFormError,
  MsgFormWarning,
  MsgLoading,
  MsgParsingSteps,
} from "./components/Msg";
import { faqBuilder } from "src/App/Lib/Helper/Markdown";
import { ApiBoundry } from "src/App/Context/ApiContext";

const InvoiceForm: React.FC<InvoiceProps> = (props: InvoiceProps) => {
  const { i18n } = useTranslation();
  const { texts } = useContext(TranslationContext);
  const { isLoading } = useContext(LoadingContext);
  const [isUploadEnabled, setIsUploadEnabled] = useState<boolean>(false);
  const [isParsing, setIsParsing] = useState<boolean>(false);
  const [isParsed, setIsParsed] = useState<boolean>(false);
  //  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);

  const [invoiceData, setInvoiceData] = useState<object | false>(false);
  const [invoiceFileID, setInvoiceFileID] = useState<string>(null);
  const [isFormWarning, setIsFormWarning] = useState<boolean>(false);
  const [isFormError, setIsFormError] = useState<boolean>(false);

  useEffect(() => {
    setIsFormWarning(false);
    setIsFormError(false);
    setIsParsed(false);
    if (!props.userData) {
      setIsUploadEnabled(true);
    }
  }, []);

  return (
    <>
      <Breadcrumb />
      <div className="row">
        <a href="#" className="mobileFaq toggleQuestion">
          {texts[i18n.language].app.show_faq}
        </a>
        <div className="content col-7 pe-3">
          <Markdown>{faqBuilder(texts[i18n.language].left)}</Markdown>
        </div>
        <div className="col-5 form-invoice">
          <h2>{texts[i18n.language].app.insert_invoice}</h2>
          <hr />
          <ApiBoundry>
            <Upload
              userData={props.userData}
              setInvoiceData={setInvoiceData}
              setInvoiceFileID={setInvoiceFileID}
              setIsSent={setIsSent}
              isUploadEnabled={isUploadEnabled}
              setIsParsing={setIsParsing}
              setIsParsed={setIsParsed}
              setIsFormWarning={setIsFormWarning}
            />

            <MsgLoading isLoading={isLoading} />
            <MsgParsingSteps isParsing={isParsing} isParsed={isParsed} />

            <MsgFormError isFormError={isFormError} />
            <MsgFormWarning isFormWarning={isFormWarning} />

            {invoiceData ? (
              <Invoice
                isParsed={isParsed}
                isSent={isSent}
                setIsSent={setIsSent}
                setIsFormError={setIsFormError}
                setIsFormWarning={setIsFormWarning}
                invoiceData={invoiceData}
                userData={props.userData}
                invoiceFileID={invoiceFileID}
              />
            ) : (
              <></>
            )}

            <MsgFormSent isSent={isSent} />
          </ApiBoundry>
        </div>
      </div>
    </>
  );
};

export default InvoiceForm;
