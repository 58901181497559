export const RulesValidationHelper = (
  rules,
  ruleKey,
  formValue,
  callback,
  callbackVal,
) => {
  if( formValue || formValue === null ){
    callback(formValue);
  }
  rules?.map(({ rule_no, status, critical, message }, id) => {
    if (rule_no === ruleKey && (status === false || critical === true)) {
      callback(callbackVal ? message : formValue ? formValue : callbackVal);
    }
  });
};

export const RulesValidationHelperNew = (
    rules,
    ruleKey,
    formValue,
    callback,
    callbackErr
) => {
  if( formValue){
    callback(formValue);
  }
  rules?.map(({ rule_no, status, critical, message }, id) => {
    if (rule_no === ruleKey && (status === false || critical === true)) {
      callbackErr(true);
      // callback(callbackVal ? message : formValue ? formValue : callbackVal);
    }
  });
};

export const RulesValidationHelperSimple = (
    rules,
    ruleKey,
    callback,
) => {
  rules?.map(({ rule_no, status, critical, message }, id) => {
    if (rule_no === ruleKey && (status === false || critical === true)) {
      callback(message);
    }
  });
};
