import React, { useState, createContext } from "react";
import { Props } from "src/App/@types/Props";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enUS from "src/App/Translations/en-US.json";
import csCZ from "src/App/Translations/cs-CZ.json";
import i18next from "i18next";

import defaults from "@bp/other/texts/Defaults.json";

import csCZApp from "@bp/other/texts/cs-CZ/UserZone/Forms/InvoiceForm/App.json";
import enUSApp from "@bp/other/texts/en-US/UserZone/Forms/InvoiceForm/App.json";

import csCZLeft from "@bp/other/texts/cs-CZ/UserZone/Forms/InvoiceForm/Left.md";
import enUSLeft from "@bp/other/texts/en-US/UserZone/Forms/InvoiceForm/Left.md";
import csCZForm from "@bp/other/texts/cs-CZ/UserZone/Forms/InvoiceForm/Form.json";
import enUSForm from "@bp/other/texts/en-US/UserZone/Forms/InvoiceForm/Form.json";

import csCZAttention from "@bp/other/texts/cs-CZ/UserZone/Forms/InvoiceForm/Attention.md";
import enUSAttention from "@bp/other/texts/en-US/UserZone/Forms/InvoiceForm/Attention.md";

const texts = {
  defaults,
  "cs-CZ": {
    app: csCZApp as object,
    left: csCZLeft as string,
    attention: csCZAttention as string,
    form: csCZForm as object,
  } as object,
  "en-US": {
    app: enUSApp as object,
    left: enUSLeft as string,
    attention: enUSAttention as string,
    form: enUSForm as object,
  } as object,
} as object;

const TranslationContext = createContext(null);

const TranslationBoundry: React.FC<Props> = (props: Props) => {
  const [appLang, setAppLang] = useState<string | null>("cs-CZ");
  const onAppLangChange = (event, lang: string) => {
    event.preventDefault();
    setAppLang(lang);
    i18next.changeLanguage(lang);
  };

  const ctx = { appLang, setAppLang, onAppLangChange, texts };

  i18n.use(initReactI18next).init({
    resources: { ...enUS, ...csCZ },
    lng: appLang,
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <TranslationContext.Provider value={ctx}>
      {props.children}
    </TranslationContext.Provider>
  );
};

export { TranslationContext, TranslationBoundry };
