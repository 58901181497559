import React, { useState, useRef, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { TranslationContext } from "src/App/Context/TranslationContext";
import { LoadingContext } from "src/App/Context/LoadingContext";
import { sendInvoice } from "src/App/Lib/Helper/Api";
import { useTranslation } from "react-i18next";
import { CATEGORY_ENUM } from "@bp/common/common/src/enum/Category.js";
import { ValidationRules } from "src/App/Lib/Enum/ValidateRules";
import {
  RulesValidationHelper,
  RulesValidationHelperNew,
  RulesValidationHelperSimple
} from "src/App/Lib/Helper/RulesValidationHelper";

const invoiceMaxAmount = 20000;

export const Invoice = ({
  invoiceData,
  userData,
  invoiceFileID,
  isParsed,
  isSent,
  setIsSent,
  setIsFormError,
  setIsFormWarning,
}) => {
  if (!isParsed || isSent) {
    return <></>;
  }

  const invoiceRef = useRef<HTMLFormElement>(null);
  const { texts } = useContext(TranslationContext);
  const { i18n } = useTranslation();
  const [recipientCompany, setRecipientCompany] = useState<string>(null);
  const [recipientCompanyErr, setRecipientCompanyErr] = useState(false);
  const [recipientCompanyID, setRecipientCompanyID] = useState<string>(null);
  const [recipientCompanyIDErr, setRecipientCompanyIDErr] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState<string>(null);
  const [recipientAddressErr, setRecipientAddressErr] = useState(false);
  const [invoiceCompany, setInvoiceCompany] = useState<string>(null);
  const [invoiceCompanyID, setInvoiceCompanyID] = useState<string>(null);
  const [invoiceCompanyIDErr, setInvoiceCompanyIDErr] = useState(false);
  const [invoiceID, setInvoiceID] = useState<string>(null);
  const [invoiceCategory, setInvoiceCategory] = useState<string>(null);
  const [invoiceAmountMax, setInvoiceAmountMax] = useState<
    string | number | boolean
  >(false);
  const [invoiceAmount, setInvoiceAmount] = useState<string>(null);
  const [isPriceSelected, setIsPriceSelected] = useState(false);
  const [invoiceBankAccountNumber, setInvoiceBankAccountNumber] =
    useState<string>(null);
  const [invoiceBankAccountNumberClicked, setInvoiceBankAccountNumberClicked] =
    useState<boolean>(false);
  const [invoiceBankCode, setInvoiceBankCode] = useState<string>(null);
  const [invoiceBankCodeClicked, setInvoiceBankCodeClicked] =
    useState<boolean>(false);
  const [invoiceVS, setInvoiceVS] = useState<string>(null);
  const [invoiceManualSwitch, setInvoiceManualSwitch] =
    useState<boolean>(false);
  const [isManualSelected, setIsManualSelected] = useState(false);
  const [isManualForced, setIsManualForced] = useState(false);
  const [isAffidavitSelected, setIsAffidavitSelected] = useState(false);
  const [isAffidavitExtended, setIsAffidavitExtended] = useState(false);
  const [note, setNote] = useState<string>(null);
  const { setIsLoading } = useContext(LoadingContext);
  const [validationWarning, setValidationWarning] = useState(false);
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    if (Number(invoiceData?.meta?.code) === -1) {
      setValidationWarning(texts[i18n.language]?.form?.formError);
    }

    verifyFields(invoiceData?.data, invoiceData?.validation);
  }, [invoiceData]);

  const verifyFields = (invoiceData, validationRules = []) => {
    console.log('Verify: '+JSON.stringify(invoiceData));

    RulesValidationHelperNew(
        validationRules,
        ValidationRules.RECIPIENT_NAME,
        invoiceData?.recipient,
        setRecipientCompany,
        setRecipientCompanyErr
    );

    RulesValidationHelperNew(
        validationRules,
        ValidationRules.RECIPIENT_ICO,
        invoiceData?.recipient_ico,
        setRecipientCompanyID,
        setRecipientCompanyIDErr
    );

    RulesValidationHelperNew(
        validationRules,
        ValidationRules.RECIPIENT_ADDR,
        invoiceData?.recipient_addr,
        setRecipientAddress,
        setRecipientAddressErr
    );

    setInvoiceCompany(invoiceData?.supplier);

    RulesValidationHelperNew(
        validationRules,
        ValidationRules.VALID_ICO,
        invoiceData?.ico,
        setInvoiceCompanyID,
        setInvoiceCompanyIDErr
    );

    setInvoiceID(invoiceData?.invoice_id);
    setInvoiceCategory(invoiceData?.category_id);

    if (invoiceData?.category_id === "OST") {
      setIsManualForced(true);
      setIsManualSelected(true);
    }

    setInvoiceAmount(invoiceData?.amount?.amount_total);
    setInvoiceBankAccountNumber(invoiceData?.bank_acc);

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.CZECH_ACCOUNT,
        setValidationWarning
    );

    RulesValidationHelper(
      validationRules,
      ValidationRules.INVALID_BANKCODE,
      invoiceData?.bank_code,
      setInvoiceBankCode,
      null
    );

    setInvoiceVS(invoiceData?.var_number);
    setInvoiceManualSwitch(invoiceRef.current?.invoiceManualSwitch.value);

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.CONTRACTUAL_PARTNER_NOT_ALLOWED,
        setValidationWarning
    );

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.INVALID_BANKCODE,
        setValidationWarning
    );

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.PAYMENT_METHOD,
        setValidationWarning
    );

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.POSITIVE_VALUE,
        setValidationWarning
    );

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.VALUE_BELOW_20,
        setValidationWarning
    );

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.SPORT_SUBJECT,
        setValidationWarning
    );

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.SPORT_ARES,
        setValidationWarning
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.CULTURE_SUBJECT,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.CULTURE_ARES,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.EDUCATION1_SUBJECT,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.EDUCATION1_ARES,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.EDUCATION2_SUBJECT,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.EDUCATION2_ARES,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.BOOK_SUBJECT,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.BOOK_ARES,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.RECREATION_SUBJECT,
      setValidationWarning,
    );
    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.RECREATION_ARES,
        setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.HEALTH_SUBJECT,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.HEALTH_UZIS,
      setValidationWarning,
    );

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.EDUCATION1_KID_NOT_ALLOWED,
        setValidationWarning
    );
    RulesValidationHelperSimple(
      validationRules,
      ValidationRules.SPORT_RENT_NOT_ALLOWED,
      setValidationWarning
    );
    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.BOOK_ADDITIONAL_NOT_ALLOWED,
        setValidationWarning
    );
    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.HEALTH_PHARMA_NOT_ALLOWED,
        setValidationWarning
    );

    RulesValidationHelperSimple(
        validationRules,
        ValidationRules.RECREATION_FOOD_NOT_ALLOWED,
        setValidationWarning
    );

  };

  const onChangeInvoiceCompany = (e: any) => {
    e.preventDefault();
    setInvoiceCompany(e.target.value);
  };

  const onChangeInvoiceCompanyID = (e: any) => {
    e.preventDefault();
    setInvoiceCompanyID(e.target.value);
    setInvoiceCompanyIDErr(false);
  };

  const onChangeInvoiceID = (e: any) => {
    e.preventDefault();
    setInvoiceID(e.target.value);
  };

  const onChangeInvoiceCategory = (e: any) => {
    e.preventDefault();
    /* TODO temp disabled
      setInvoiceMainCategory(e.target.value);
      */
    setInvoiceCategory(e.target.value);

    if (e.target.value === "OST") {
      setIsManualForced(true);
      setIsManualSelected(true);
    } else {
      setIsManualForced(false);
      setIsManualSelected(false);
    }
  };

  /* TODO temp disabled
    const onChangeInvoiceSubCategory = (e: any) => {
      e.preventDefault();
      setInvoiceCategory(e.target.value);
    };
    */

  const onChangeInvoiceVS = (e: any) => {
    e.preventDefault();
    if (/^\d+$/.test(e.target.value)) {
      setInvoiceVS(e.target.value);
    } else {
      setInvoiceVS(null);
    }
  };

  const onChangeInvoiceAmount = (e: any) => {
    e.preventDefault();
    setIsPriceSelected(e.target.value === "own" ? true : false);
    setInvoiceAmount(e.target.value !== "own" ? e.target.value : false);
    setInvoiceAmountMax(
      parseInt(e.target.value, 10) >= invoiceMaxAmount ? true : false
    );
  };

  const onChangeInvoiceAmountUser = (e: any) => {
    e.preventDefault();
    setInvoiceAmount(e.target.value);
    setInvoiceAmountMax(
      parseInt(e.target.value, 10) >= invoiceMaxAmount ? true : false
    );
  };

  const onChangeBankAccount = (e: any) => {
    e.preventDefault();
    setInvoiceBankAccountNumber(e.target.value);
  };

  const onChangeBankCode = (e: any) => {
    e.preventDefault();
    setInvoiceBankCode(e.target.value);
  };

  const onClickSendForm = async (e: any) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setValidationWarning(null);
    setIsLoading(true);
    setIsFormWarning(false);

    await sendInvoice(
      invoiceRef.current?.recipientCompany.value,
      invoiceRef.current?.recipientCompanyID.value,
      invoiceRef.current?.recipientAddress.value,
      invoiceRef.current?.invoiceCompany.value,
      invoiceRef.current?.invoiceCompanyID.value,
      invoiceID,
      invoiceAmount,
      invoiceRef.current?.invoiceCategory.value,
      userData.user_email,
      userData.user_phone,
      note,
      invoiceRef.current?.invoiceBankAccountNumber.value,
      invoiceRef.current?.invoiceBankCode.value,
      invoiceVS,
      isManualSelected,
      invoiceFileID,
      userData.token,
      (res) => {
        let validationCorrect;
        setIsLoading(false);
        setInvoiceAmount(null);
        setIsFormError(false);
        validationCorrect = res?.validation?.map((val, key) => {
          if (val?.status === false) {
            return false;
          }
        });

        if (validationCorrect?.includes(false)) {
          setIsFormWarning(true);
          verifyFields(res.data, res?.validation);
        } else {
          setIsSent(true);
        }
      },
      (res) => {
        setIsLoading(false);
        setIsFormError(true);
      }
    );
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    e.target.reset();
  };

  const onClickManualSwitch = (e: any) => {
    setIsManualSelected(e.target.checked);
  };

  const onClickAffidavit = (e: any) => {
    setIsAffidavitSelected(e.target.checked);
  };

  const onClickShowAffidavitExtended = (e: any) => {
    e.preventDefault();
    setIsAffidavitExtended(!isAffidavitExtended);
  };

  const onClickRecipientCompanyEdit = (e: any) => {
    e.preventDefault();
    setRecipientCompany(texts?.defaults.recipientCompany);
    setRecipientCompanyErr(false);
  };

  const onClickRecipientCompanyIDEdit = (e: any) => {
    e.preventDefault();
    setRecipientCompanyID(texts?.defaults.recipientCompanyID);
    setRecipientCompanyIDErr(false);
  };

  const onClickRecipientAddressEdit = (e: any) => {
    e.preventDefault();
    setRecipientAddress(texts?.defaults.recipientAddress);
    setRecipientAddressErr(false)
  };

  const onClickinvoiceBankAccountNumberEdit = (e: any) => {
    e.preventDefault();
    setInvoiceBankAccountNumberClicked(!invoiceBankAccountNumberClicked);
  };

  const onClickinvoiceBankCodeEdit = (e: any) => {
    e.preventDefault();
    setInvoiceBankCodeClicked(!invoiceBankCodeClicked);
  };

  return (
    <>
      {validationWarning ? (
        <p className="warning">{validationWarning}</p>
      ) : (
        <></>
      )}

      {validationError ? (
        <p className="error">{validationError}</p>
      ) : (
        <></>
      )}

      <form ref={invoiceRef} onSubmit={onSubmit}>
        <h2>{texts[i18n.language]?.app.processed_invoice}</h2>
        <hr />

        <div className="mt-2 mb-5 section-invoice">
          <fieldset className="mb-4">
            <legend>{texts[i18n.language]?.app.recipient_details}</legend>

            <Form.Group className="mb-3" controlId="recipientCompany">
              <Form.Label>
                {texts[i18n.language]?.form.recipientCompany}
                <a href="#" onClick={onClickRecipientCompanyEdit}>
                  {texts[i18n.language]?.app.set_default}
                </a>
              </Form.Label>
              <Form.Control
                disabled={true}
                readOnly={true}
                className={recipientCompany ? "" : "error"}
                value={recipientCompany}
                required
              />
              {recipientCompany === null || recipientCompanyErr? (
                <p className="error">
                  {texts[i18n.language]?.form.recipientCompanyError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="recipientCompanyID">
              <Form.Label>
                {texts[i18n.language]?.form.recipientCompanyID}
                <a href="#" onClick={onClickRecipientCompanyIDEdit}>
                  {texts[i18n.language]?.app.set_default}
                </a>
              </Form.Label>
              <Form.Control
                type="string"
                disabled={true}
                readOnly={true}
                className={
                  recipientCompanyID || recipientCompanyID !== null
                    ? ""
                    : "error"
                }
                value={recipientCompanyID}
                required
              />
              {recipientCompanyID === null || recipientCompanyIDErr ? (
                <p className="error">
                  {texts[i18n.language]?.form.recipientCompanyIDError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="recipientAddress">
              <Form.Label>
                {texts[i18n.language]?.form.recipientAddress}
                <a href="#" onClick={onClickRecipientAddressEdit}>
                  {texts[i18n.language]?.app.set_default}
                </a>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                disabled={true}
                readOnly={true}
                className={
                  recipientAddress || recipientAddress !== null ? "" : "error"
                }
                value={recipientAddress}
                required
              />
              {recipientAddress === null || recipientAddressErr? (
                <p className="error">
                  {texts[i18n.language]?.form.recipientAddressError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>
          </fieldset>

          <fieldset className="mb-4">
            <legend>{texts[i18n.language]?.app.supplier_details}</legend>

            <Form.Group className="mb-3" controlId="invoiceCompany">
              <Form.Label>
                {texts[i18n.language]?.form.invoiceCompany}
              </Form.Label>
              <Form.Control
                placeholder={
                  texts[i18n.language]?.form.invoiceCompanyPlaceholder
                }
                value={invoiceCompany}
                required
                className={invoiceCompany ? "" : "error"}
                onChange={onChangeInvoiceCompany}
              />
              {!invoiceCompany ? (
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceCompanyError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="invoiceCompanyID">
              <Form.Label>
                {texts[i18n.language]?.form.invoiceCompanyID}
              </Form.Label>
              <Form.Control
                placeholder={
                  texts[i18n.language]?.form.invoiceCompanyIDPlaceholder
                }
                type="number"
                value={invoiceCompanyID}
                required
                className={invoiceCompanyID ? "" : "warning"}
                onChange={onChangeInvoiceCompanyID}
              />
              {!invoiceCompanyID || invoiceCompanyIDErr ? (
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceCompanyIDError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="invoiceID">
              <Form.Label>{texts[i18n.language]?.form.invoiceID}</Form.Label>
              <Form.Control
                placeholder={texts[i18n.language]?.form.invoiceIDPlaceholder}
                type="number"
                min="0"
                value={invoiceID}
                required
                className={invoiceID ? "" : "warning"}
                onChange={onChangeInvoiceID}
              />
              {!invoiceID ? (
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceIDError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>
          </fieldset>

          <fieldset className="mb-4">
            <legend>{texts[i18n.language]?.app.categories}</legend>
            <Form.Group className="mb-3" controlId="invoiceCategory">
              <Form.Label>{texts[i18n.language]?.app.service_type}</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={invoiceCategory}
                onChange={onChangeInvoiceCategory}
                className={invoiceCategory ? "" : "warning"}
              >
                <option value="">
                  {texts[i18n.language]?.app.please_select}
                </option>

                {Object.values(CATEGORY_ENUM).map(
                  ({ code, label, label_en }) => {
                    const l = { "cs-CZ": label, "en-US": label_en };
                    return (
                      <option key={code} value={code}>
                        {l[i18n.language]}
                      </option>
                    );
                  }
                )}
              </Form.Select>
              {!invoiceCategory ? (
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceCategoryError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>

            {/*
            Disabled on client request
            <Form.Group className="mb-3" controlId="invoiceSubCategory">
              <Form.Label>{texts[i18n.language]?.app.service_subtype}</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={onChangeInvoiceSubCategory}
              >
                <option value="">{texts[i18n.language]?.app.please_select}</option>
                {Object.entries(SUBCATEGORY_ENUM).map(([key]) => {
                  return invoiceCategory ===
                    SUBCATEGORY_ENUM[key].parent ? (
                    <option key={key} value={key}>
                      {SUBCATEGORY_ENUM[key].label}
                    </option>
                  ) : (
                    <></>
                  );
                })}
              </Form.Select>
            </Form.Group>
              */}
          </fieldset>

          <fieldset className="mb-4">
            <legend>{texts[i18n.language]?.app.bank_details}</legend>

            <Form.Group className="mb-3" controlId="invoiceAmount">
              <Form.Label>
                {texts[i18n.language]?.form.invoiceAmount}
              </Form.Label>
              <Form.Select
                onChange={onChangeInvoiceAmount}
                className={`effect-ease-out ${
                  invoiceAmountMax ? "warning" : ""
                }`}
                value={invoiceAmount}
              >
                <option value="">
                  {texts[i18n.language]?.app.please_select}
                </option>
                {invoiceData?.data?.amount &&
                  Object.entries(invoiceData?.data?.amount).map(([key, el]) => {
                    return Boolean(el) ? (
                      <option key={key} value={String(el)}>
                        {texts[i18n.language]?.form[key]}: {String(el)}
                      </option>
                    ) : (
                      <></>
                    );
                  })}

                {/*
                          Disabled on client request
                          <option value="own">
                            {texts[i18n.language]?.app.other_amount}
                          </option>
                          */}
              </Form.Select>
              {isPriceSelected ? (
                <>
                  <br />
                  <Form.Label>
                    {texts[i18n.language]?.form.invoiceAmountUser}
                  </Form.Label>
                  <Form.Control
                    placeholder={
                      texts[i18n.language]?.form.invoiceAmountUserPlaceholder
                    }
                    type="number"
                    min="0.00"
                    max="1000000.00"
                    step="0.01"
                    id="invoiceAmountUser"
                    name="invoiceAmountUser"
                    onChange={onChangeInvoiceAmountUser}
                    className={
                      isPriceSelected
                        ? "effect-blink-border-3-times"
                        : invoiceAmount
                        ? "error"
                        : ""
                    }
                  />
                </>
              ) : (
                <></>
              )}

              {invoiceAmountMax !== false ? (
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceAmountWarning}
                </p>
              ) : (
                <></>
              )}

              {Number(invoiceAmount) <= 0 ? (
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceAmountNegativeZeroWarning}
                </p>
              ) : (
                <></>
              )}

              {!invoiceAmount || !invoiceData?.data?.amount ? (
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceAmountError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="invoiceBankAccountNumber">
              <Form.Label>
                {texts[i18n.language]?.form.invoiceBankAccountNumber}
                {!invoiceBankAccountNumberClicked ? (
                  <a href="#" onClick={onClickinvoiceBankAccountNumberEdit}>
                    Editovat
                  </a>
                ) : (
                  <></>
                )}
              </Form.Label>
              <Form.Control
                type="string"
                placeholder={
                  texts[i18n.language]?.form.invoiceBankAccountNumberPlaceholder
                }
                required
                readOnly={!invoiceBankAccountNumberClicked}
                className={invoiceBankAccountNumber ? "" : "error"}
                defaultValue={invoiceBankAccountNumber}
                onChange={onChangeBankAccount}
              />
              {!invoiceBankAccountNumber ? (
                <p className="error">
                  {texts[i18n.language]?.form.invoiceBankAccountNumberError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="invoiceBankCode">
              <Form.Label>
                {texts[i18n.language]?.form.invoiceBankCode}
                {!invoiceBankCodeClicked ? (
                  <a href="#" onClick={onClickinvoiceBankCodeEdit}>
                    Editovat
                  </a>
                ) : (
                  <></>
                )}
              </Form.Label>
              <Form.Control
                placeholder={
                  texts[i18n.language]?.form.invoiceBankCodePlaceholder
                }
                type="number"
                required
                onChange={onChangeBankCode}
                readOnly={!invoiceBankCodeClicked}
                className={
                  invoiceBankCode === null ||
                  invoiceBankCode?.length < 4 ||
                  invoiceBankCode?.length > 4 ||
                  invoiceData?.bank_code === "6800"
                    ? "error"
                    : ""
                }
                defaultValue={invoiceBankCode}
              />
              {invoiceData?.bank_code === "6800" ? (
                <p className="error">
                  {texts[i18n.language]?.form.invoiceBankCodeError6800}
                </p>
              ) : (
                <></>
              )}
              {!invoiceBankCode ||
              invoiceBankCode?.length < 4 ||
              invoiceBankCode?.length > 4 ? (
                <p className="error">
                  {texts[i18n.language]?.form.invoiceBankCodeError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="invoiceVS">
              <Form.Label>{texts[i18n.language]?.form.invoiceVS}</Form.Label>
              <Form.Control
                placeholder={texts[i18n.language]?.form.invoiceVSPlaceholder}
                required
                value={invoiceVS}
                className={invoiceVS ? "" : "warning"}
                onChange={onChangeInvoiceVS}
              />
              {!invoiceVS ? (
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceVSError}
                </p>
              ) : (
                <></>
              )}
            </Form.Group>
          </fieldset>

          {/*
            <fieldset className="mb-4">
              <legend>{texts[i18n.language]?.app.personal_details}</legend>

              <Form.Group className="mb-3" controlId="userEmail">
                <Form.Label>{texts[i18n.language]?.app.your_email_address}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="user@domain.com"
                  defaultValue={userData?.user_email ? userData.user_email : null}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="userPhone">
                <Form.Label>
                  {texts[i18n.language]?.app.your_phone_number}
                </Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="+420123456789"
                  defaultValue={userData?.user_phone ? userData.user_phone : null}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="userNote">
                <Form.Label>{texts[i18n.language]?.app.note}</Form.Label>
                <Form.Control as="textarea" placeholder="" rows={5} />
              </Form.Group>
            </fieldset>
            */}

          <fieldset className="mb-4">
            <legend>{texts[i18n.language]?.app.manual_processing}</legend>
            <Form.Group className="mb-3" controlId="invoiceManualSwitch">
              <Form.Check
                type="switch"
                label={texts[i18n.language]?.form.invoiceManualSwitchLabel}
                onChange={onClickManualSwitch}
                value={
                  isManualForced ? String(true) : String(invoiceManualSwitch)
                }
                checked={isManualForced ? true : undefined}
              />
            </Form.Group>

            {isManualSelected || isManualForced ? (
              <Form.Group className="mb-3">
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceManualWarning}
                </p>
              </Form.Group>
            ) : (
              <></>
            )}
          </fieldset>

          <fieldset className="mb-4">
            <legend>{texts[i18n.language]?.app.affidavit} </legend>
            <Form.Group className="mb-3 Affidavit" controlId="invoiceAffidavit">
              <Form.Check
                type="switch"
                label={
                  isAffidavitExtended
                    ? texts[i18n.language]?.form.invoiceAffidavitExtended
                    : texts[i18n.language]?.form.invoiceAffidavit
                }
                onChange={onClickAffidavit}
                value={String(isAffidavitSelected)}
              />
            </Form.Group>

            {!isAffidavitExtended ? (
              <a
                href="#"
                onClick={onClickShowAffidavitExtended}
                className="invoiceAffidavitExtended"
              >
                {texts[i18n.language]?.app.show_more}
              </a>
            ) : (
              <></>
            )}
            {!isAffidavitSelected ? (
              <Form.Group className="mb-3 AffidavitError">
                <p className="warning">
                  {texts[i18n.language]?.form.invoiceAffidavitWarning}
                </p>
              </Form.Group>
            ) : (
              <></>
            )}
          </fieldset>

          <input
            type="hidden"
            disabled={true}
            name="invoiceManual"
            value={invoiceAmountMax || isManualSelected ? 1 : 0}
          />

          <Form.Group className="mb-3" controlId="userID">
            <Form.Control
              type="hidden"
              defaultValue={userData?.user_id ? userData.user_id : null}
            />
          </Form.Group>

          {/*
            !recipientCompany || !recipientCompanyID || !recipientAddress ? (
              <Form.Group className="mb-3" controlId="userPhone">
                <p className="error">{texts[i18n.language]?.form.invoiceError}</p>
              </Form.Group>
            ) : (
              <></>
            )
            */}

          <Button
            variant="primary"
            className="green"
            type="submit"
            disabled={
              invoiceManualSwitch &&
              (!isAffidavitSelected ||
              !recipientCompany ||
              !recipientCompanyID ||
              !recipientAddress ||
              !invoiceCompany ||
              !invoiceCompanyID ||
              !invoiceID ||
              !invoiceCategory ||
              !invoiceAmount ||
              !invoiceBankAccountNumber ||
              !invoiceBankCode ||
              !invoiceVS ||
              Number(invoiceAmount) <= 0 ||
              validationError
                ? true
                : false)
            }
            onClick={onClickSendForm}
          >
            {texts[i18n.language]?.app.send_invoice}
          </Button>
        </div>
      </form>
    </>
  );
};
