import React, { useState, createContext } from "react";
import { Props } from "src/App/@types/Props";

const LoadingContext = createContext(null);

const LoadingBoundry: React.FC<Props> = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {props.children}
      {isLoading ? (
        <div className="LoadingBoundary">
          <img className="icon-loading" src="/assets/i/icons/loading.svg" />
        </div>
      ) : (
        <></>
      )}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingBoundry };
