export enum ValidationRules {
    RECIPIENT_NAME = 1,
    RECIPIENT_ADDR = 2,
    RECIPIENT_ICO = 3,
    VALID_ICO = 4,
    PAYMENT_METHOD = 5,
    INVALID_BANKCODE = 6,
    POSITIVE_VALUE = 7,
    VALUE_BELOW_20 = 8,
    CZECH_ACCOUNT = 9,
    SPORT_SUBJECT = 10,
    SPORT_ARES = 11,
    CULTURE_SUBJECT = 13,
    CULTURE_ARES = 14,
    EDUCATION1_SUBJECT = 15,
    EDUCATION1_ARES = 16,
    EDUCATION2_SUBJECT = 17,
    EDUCATION2_ARES = 18,
    BOOK_SUBJECT = 19,
    BOOK_ARES = 20,
    RECREATION_SUBJECT = 21,
    RECREATION_ARES = 22,
    HEALTH_SUBJECT = 23,
    HEALTH_UZIS = 24,
    EDUCATION1_KID_NOT_ALLOWED = 25,
    SPORT_RENT_NOT_ALLOWED = 26,
    BOOK_ADDITIONAL_NOT_ALLOWED = 27,
    HEALTH_PHARMA_NOT_ALLOWED = 28,
    RECREATION_FOOD_NOT_ALLOWED = 29,
    CONTRACTUAL_PARTNER_NOT_ALLOWED = 30
}