import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { LoadingBoundry } from "src/App/Context/LoadingContext";
import { TranslationBoundry } from "src/App/Context/TranslationContext";
import { Props } from "src/App/@types/Props";

import UserZone from "src/App/Components/UserZone/UserZone";

import "src/App/App.scss";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

const App: React.FC<Props> = () => {

  const ErrorFallback = () => {
    return (
      <div className="ErrorBoundary">
        <div className="box">
          <h1>Application error</h1>
          <p>Please contact our support center: <strong>+420 225 377 777</strong>&nbsp;
          and/or go back to main application <a href="https://inside.benefit-plus.eu/Pages/LoginNEW.aspx">benefit-plus.eu</a></p>
        </div>
      </div>
    )
  }

  return (
    <ErrorBoundary fallbackRender={ErrorFallback}>
      <TranslationBoundry>
        <LoadingBoundry>
          <UserZone />
        </LoadingBoundry>
      </TranslationBoundry>
    </ErrorBoundary>
  );
};

export default App;
